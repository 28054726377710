import logo from "./logo.svg";
import "./App.css";
import { Avatar } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

function App() {
  const [blog, setblog] = useState(null);
  useEffect(() => {
    axios
      .get("https://api.beatsbot.in/api/releases", {
        withCredentials: true,
      })
      .then(function (response) {
        setblog(response?.data);
      });
  }, []);

  return (
    <div className="changelog__body px-20 py-12">
      <div className="changelog__navbar mx-8">
        <div className="flex flex-nowrap items-center">
          <div>
            <img
              src={`https://cdn.beatsbot.in/Beatstr.png`}
              className="changelog__navbar_logo w-[90px] h-max"
              alt="logo"
            />
          </div>
          <div class="changelog__text text-[hsla(0,0%,100%,.8)] hover:text-sky-300 font-extrabold ml-2 cursor-pointer">
            Beats
          </div>
        </div>
      </div>
      <div className="changelog__content pt-10">
        <div className="changelog__title underline decoration-indigo-500 transition ease-in-out delay-150 hover:-translate-y-1 duration-300 text-[hsla(0,0%,100%,.8)] hover:text-sky-300 font-extrabold cursor-pointer text-center my-10 text-5xl ">
          Changelog
        </div>
        {blog?.releases.map((data) => (
          <div class="changelog__postmain">
            <div className="changelog__post transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 rounded-lg mx-12 my-12 flex flex-nowrap shadow-lg shadow-cyan-500/50 flex justify-between">
              <div>
                <img
                  src={data.image_url}
                  className="w-full h-full rounded-l-lg"
                ></img>
              </div>
              <div className="changelog__description">
                <p class="flex text-left text-slate-300 px-8 my-5 text-xl font-bold">
                  {data.version}
                </p>
                <p class="flex text-left px-8 my-5 text-sm font-bold">
                  {data.description}
                </p>
                <p class="flex text-left text-slate-300 px-8 my-5 text-sm font-light">
                  {data.date}
                </p>
              </div>
              <div className="changelog__post_author flex items-end rounded-lg px-5 py-3 text-slate-200">
                <Avatar
                  className="rounded"
                  alt=""
                  src={`https://cdn.beatsbot.in/Beats.png`}
                />
                &nbsp;{data.author}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
